import { http } from '~/utils/http'
import type { Response } from '~/type'

// 真人验证
export async function verificationGetUrl() {
  return http<Response<any>>('/app-api/member/verification/getUrl', {
    method: 'POST',
    body: {},
    noMsg: true
  })
}
